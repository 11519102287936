<template>
  <div>
    <div class="wrapper grey lighten-4">
      <NavBar></NavBar>
      <div id="content-page" class="content-page p-0 m-0">
        <router-view />
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import NavBar from '../components/socialvue/navbars/NavBar';
import profile from '../assets/images/page-img/avatar.png';
import Footer from '../components/socialvue/footer/Footer';
import { Users } from '@/FackApi/api/chat';

export default {
  name: 'Layout1',
  components: {
    NavBar,
    Footer
  },
  data () {
    return {
      userProfile: profile,
      usersList: Users
    };
  }
};
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");
</style>
